export default {
  state:{
    avatarLoaded: false,
    currentLanguage: 'en-US',
    avatarSpeech: '',
    textBubbleText: '',
    isSpeaking: false,
    defaultBubbleText: false,
    count: 0
  },
  getters:{
    getCurrentLanguage: state => state.currentLanguage,
    getAvatarSpeech: state => state.avatarSpeech,
    getTextBubbleText: state => state.textBubbleText,
    getIsSpeaking: state => state.isSpeaking,
    getAvatarLoaded: state => state.avatarLoaded,
    getDefaultBubbleText: state => state.defaultBubbleText
  },
  mutations:{
    setCurrentLanguage(state, lang){
      state.currentLanguage = lang;
    },
    setIsSpeaking(state, status){
      state.isSpeaking = status;
    },
    setAvatarSpeech(state, speech){
      state.avatarSpeech = speech;
    },
    clearAvatarSpeech(state){
      state.avatarSpeech = '';
    },
    setTextBubbleText(state, text){
      state.textBubbleText = text;
    },
    clearTextBubbleText(state){
      state.textBubbleText = '';
    },
    setAvatarLoaded(state, status){
      state.avatarLoaded = status;
    },
    setDefaultBubbleText(state, status){
      state.defaultBubbleText = status
    }
  },
  actions:{
    avatarSpeak({state,commit}, speech){
      return new Promise((resolve, reject) => {
        if(speech === null || speech === '') return reject('Nothing to speak');
        commit('clearAvatarSpeech');
          setTimeout(()=>{
            commit('setAvatarSpeech', speech);
            commit('setTextBubbleText', speech);
          }, 500);
        resolve('Speech request sent to SitePal')
      })
    },
    loadAvatar({getters, state}){
      if(state.count === 0){
        state.count = 1
        console.log('Avatar Loading Started')
        var sc = document.createElement('script');
        sc.type = "text/javascript";
        console.log('SitePal ID', getters.getSitepalID)
        //sc.src = "//vhss-d.oddcast.com/vhost_embed_functions_v4.php?acc=" + this.getSitepalID + "&js=0";
        sc.src = "//vhss-d.oddcast.com/vhost_embed_functions_v4.php?acc=" + getters.getSitepalID + "&js=0";

        //sc.src = this.getSitepalID
        sc.onload = () => {
          setTimeout(() => {
            if (typeof AC_VHost_Embed == 'function') {
              var script = document.createElement('script');
              script.type = "text/javascript";
              script.innerHTML =
                  "AC_VHost_Embed(7295865,960,960,'',1,0," + getters.getAvatarID + ",0,1,1,'RwZIxMafWux6BWjhauvuPFZP3UcJLcqh',0, '400|300|15|15|L|T|false|0|0|0|0|0|C|0|0|0|992')";
              document.getElementById('divVHSS').appendChild(script);
            }
          }, 0)
        }
        document.head.appendChild(sc);
        console.log('Avatar Loading Ended')
      }
    }
  }
}
