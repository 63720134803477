import gql from 'graphql-tag'

// ---------------------------- Homepages --------------------------------------------

export const homepageEnglishQuery = gql`

  query homeQuery {
    homepages(sort: "displayPosition") {
      id
      speech    
      menuName     
      pageType{
        ... on ComponentHomepageNavigation{
          __typename
          pathName
          pageHeader
        }
        ... on ComponentHomepageDialogBox{
          __typename
          type
          popup{
            ... on ComponentDisplayPopup{
              name
              imageName
              speech
              image{
                id
                url
              }
            }
          }
        }
      }
      menuIcon {
        id
        url
      }
      displayPosition
    }
  }
`

// ------------------------------ Faqs ----------------------------------------------

export const faqEnglishQuery = gql`
query faqQuery {
  faqs {
    id
    question
    answer
    category
  }
}
`
export const faqCategoryEnglishQuery = gql`
query faqCategoryQuery {
  faqCategories(sort: "displayOrder") {
    id
    categoryName
    displayType
    displayOrder
  }
}
`
// ------------------------------ Maps ----------------------------------------------
export const mapEnglishQuery = gql`
query mapQuery {
  maps{
    id
    mapName
    mapFloor
    speech
    mapImage {
      id
      url
    }
  }
}
`

export const floorEnglishQuery = gql`
query floorQuery {
  floors (sort: "floorNumber"){ 
    id
    floorName
    floorNumber
    speech
    floorImage {
      id
      url
    }
  }
}
`
// ------------------------------ Popups ----------------------------------------------

export const popupEnglishQuery = gql`
query popupQuery {
  popups{ 
    id
    popupName
    popupImage{
      id
      url
    }
  }
}
`