import { render, staticRenderFns } from "./sessionEndViewer.vue?vue&type=template&id=5b1a5ab3&scoped=true&"
import script from "./sessionEndViewer.vue?vue&type=script&lang=js&"
export * from "./sessionEndViewer.vue?vue&type=script&lang=js&"
import style0 from "./sessionEndViewer.vue?vue&type=style&index=0&id=5b1a5ab3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b1a5ab3",
  null
  
)

/* custom blocks */
import block0 from "./sessionEndViewer.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VCard,VCardText,VCardTitle,VDialog})
