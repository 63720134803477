import { UniqueDirectiveNamesRule } from 'graphql';
import * as fb from "@/firebase/index.js";
import router from '../../router';

export default {
  state:{
    kioskProfile:{},
    kioskId: undefined,
    count: 0
  },
  getters:{
    getKioskId: state => state.kioskId,
    getKioskProfile: state => state.kioskProfile,
    getCMSlink: state => state.kioskProfile.data().cmsLink,
    getAzureHost: state => state.kioskProfile.data().host,
    getAzureRoute: state => state.kioskProfile.data().route,
    getAzureEndpointKey: state => state.kioskProfile.data().endpointKey,
    getSitepalID: state => state.kioskProfile.data().sitepalId,
    getAvatarID: state => state.kioskProfile.data().avatarId,
  },
  mutations:{
    setKioskId(state, kioskId){
      state.kioskId = kioskId;
    },
    setKioskProfile(state, profile){
      state.kioskProfile = profile;
    }
  },
  actions:{
    login({dispatch}, loginObj){
      return new Promise((resolve, reject) => {
        fb.auth.signInWithEmailAndPassword(loginObj.email, loginObj.password)
          .then(cred => {
            console.log('Kiosk loggedIn successfully:', cred.user.uid);
            dispatch('fetchKioskProfile', cred.user);
            resolve('Successfully LoggedIn');
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    fetchKioskProfile({commit, dispatch, state}, kiosk){
      fb.kioskCollection.doc(kiosk.uid)
      .onSnapshot(kioskData => {
        commit('setKioskId', kiosk.uid);
        commit('setKioskProfile', kioskData);
        console.log('Kiosk Data: ', kioskData.data())
        //dispatch('checkMediaDevices')
        dispatch('loadAvatar')
          if(router.currentRoute.path !== '/' && router.currentRoute.path !== '/loadData' && state.count === 0){
            state.count = 1
            router.push('/loadData')
          }
      })
    },
    logout({commit}){
      return new Promise((resolve, reject) => {
        fb.auth.signOut()
        .then(() => {
          commit('setKioskId', undefined);
          commit('setKioskProfile', {});
          resolve('Successfully Logged Out');
          location.reload();
        })
        .catch(error => {
          reject(error.message);
        })
      })
    },
    manageKioskPresence({state}, kiosk){
      var kioskStatusDatabaseRef = fb.firebase.database().ref('/status/' + kiosk.uid);
      console.log(state.kioskId);

      var isOfflineForDatabase = {
          state: 'Unavailable',
          last_changed: fb.firebase.database.ServerValue.TIMESTAMP,
      };

      var isOnlineForDatabase = {
          state: 'Available',
          last_changed: fb.firebase.database.ServerValue.TIMESTAMP,
      };

      fb.firebase.database().ref('.info/connected').on('value', function(snapshot) {
          // If we're not currently connected, don't do anything.
          if (snapshot.val() === false) return;

          kioskStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function() {
            kioskStatusDatabaseRef.set(isOnlineForDatabase);
          });
      });
  },
    sendEmail({state}, emailData){
      console.log(state.kioskId);
      return new Promise((resolve, reject) => {
        const mailObj = {
          to: emailData.address,
          message: {
            subject: emailData.subject,
            html: emailData.content,
          }
        }
        fb.mailCollection.add(mailObj)
            .then(() => {
              resolve('Email sent successfully')
            })
            .catch(error => {
              reject(error.message)
            })
      })
    },
    sendQnaData({state, commit}, data){
      fb.qnaCollection.add(data)
          .then(response => {
            console.log(state.kioskId);
            //commit('setUserQnaRequest', response.id);
            console.log("QNA analytics added successfully")
          })
          .catch(error => {
            console.log(error.message);
          })
    }
  }
}
