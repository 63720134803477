<template>
  <v-dialog v-model="getOptionsViewer.show" width="45%" persistent>
    <v-card class="pb-2">
      <v-card-title class="primary">
        <v-row no-gutters>
          <v-col cols="11" align-self="center" align="center">
            <span
              class="
                justify-center
                text-h4
                font-weight-bold
                white--text
                text--darken-3
              "
              >{{ getOptionsViewer.dialogHeader }}</span
            >
          </v-col>
          <v-col cols="1" align-self="center" align="end">
            <v-btn icon @click="closeDialog()">
              <v-icon color="white" x-large>mdi-close-thick</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text align="center" class="pa-0">
        <v-row no-gutters>
          <v-col cols="12">
            <v-card
              v-for="(item, index) in getOptionsViewer.dialogData"
              :key="index"
              class="my-3"
              width="85%"
              @click="viewElement(item)"
              style="border: 2px solid #3357c0 !important"
            >
              <v-card-text class="black--text text-h5">
                <v-row>
                  <v-col cols="10" align="start">
                    <span v-if="typeof item === 'object'">
                      {{ item.name }}
                    </span>
                    <span v-else>
                      {{ item }}
                    </span>
                  </v-col>
                  <v-col cols="2" self-align="end" class="pa-2">
                    <v-btn fab small color="primary">
                      <v-icon color="white">mdi-gesture-tap</v-icon>
                      </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- <v-divider vertical></v-divider>
          <v-col cols="3" align-self="center"> 
            <v-card width="90%" @click="$router.push('faqs');$store.commit('closeOptionsViewer')">
              <v-img src="https://cms.akron.arsconnect.com/uploads/icon_cb2bb38587.png" width="60%" contain> </v-img>
              <v-card-title class="justify-center text-h4 font-weight-bold primary--text">FAQs</v-card-title>
            </v-card>
          </v-col> -->
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "optionsViewer",
  data() {
    return {
      talkButtonSrc: "talkButton.png",
    };
  },
  computed: {
    ...mapGetters([
      "getOptionsViewer",
      "getIsSpeaking",
      "speechRecognitionStatus",
      "getQnaStatus",
      "getResetKioskStatus",
    ]),
  },
  methods: {
    viewElement(input) {
      // Touch Usage
      this.$store.commit("setTouchRequest", {
        module: "Options Viewer",
        action: input.name,
        response: "Action executed on touch",
        timeStamp: new Date(),
        requestType: "Touch",
      });
      console.log("Requested Query", input);
      this.$store.dispatch("requestQnA", input.name)

      // this.$store.dispatch("openImageViewer", {
      //   url: input.url,
      //   name: input.imageName
      // });
      this.$store.commit("closeOptionsViewer");
    },
    closeDialog() {
      this.$store.commit("closeOptionsViewer");
      this.$store.dispatch("kioskStillThere");
    },
    actionOnTap() {
      console.log("Is Speaking", this.getIsSpeaking);
      if (!this.getIsSpeaking) {
        if (this.getResetKioskStatus) {
          this.$store.commit("setSpeechRecognitionHandler", true);
        } else {
          //this.$store.commit('closeOptionsViewer')
          this.$store.commit("setSpeechRecognitionHandler", true);
        }
      } else {
        window.stopSpeech();
        this.talkButtonSrc = "talkButton.png";
      }
    },
  },
  watch: {
    getIsSpeaking(newVal) {
      if (newVal) {
        this.talkButtonSrc = "stopTalking.png";
      }
      if (!newVal && !this.speechRecognitionStatus) {
        this.talkButtonSrc = "talkButton.png";
      }
    },
    speechRecognitionStatus(newVal) {
      if (newVal) {
        this.talkButtonSrc = "talkButtonActive.webp";
      } else {
        this.talkButtonSrc = "talkButton.png";
      }
    },
  },
};
</script>

<style scoped>
.talk-button {
  filter: sepia(19%) saturate(697%) hue-rotate(294deg) brightness(95%)
    contrast(350%);
}
</style>
