import gql from 'graphql-tag'

//---------------------------- HomePage Queries ---------------------------------

export const homepageSpanishQuery = gql`
query homeQuery {
  homepages (sort: "displayPosition", locale: "es-US") {
    id
    speech    
    menuName     
    pageType{
      ... on ComponentHomepageNavigation{
        __typename
        pathName
        pageHeader
      }
      ... on ComponentHomepageDialogBox{
        __typename
        type      
      }
    }
    menuIcon {
      id
      url
    }
    displayPosition
  }
}
`

//---------------------------- Faq Page Queries ---------------------------------

export const faqSpanishQuery = gql`
query faqQuery {
  faqs (locale: "es-US") {
    id
    question
    answer
    category
  }
}
`

export const faqCategorySpanishQuery = gql`
query faqCategoryQuery {
  faqCategories (locale: "es-US") {
    id
    categoryName
    displayType
  }
}
`

export const srlGuideSpanishQuery = gql`query srlGuideQuery {
  srlGuides (sort: "id", locale: "es-US"){
    id
    category
    guideName
    srlGuideLink
    srlFile {
      id
      url
    }
  }
}`


//---------------------------- Map Page Queries ---------------------------------



export const mapSpanishQuery = gql`
query mapQuery {
  maps (locale: "es-US") {
    id
    mapName
    mapFloor
    speech
    mapImage {
      id
      url
    }
  }
}
`

export const floorSpanishQuery = gql`
query floorQuery {
  floors (sort: "floorNumber", locale: "es-US"){ 
    id
    floorName
    floorNumber
    speech
    floorImage {
      id
      url
    }
  }
}
`

// ------------------------------ Popups ----------------------------------------------

export const popupSpanishQuery = gql`
query popupQuery {
  popups (locale: "es-US"){  
    id
    popupName
    popupImage{
      id
      url
    }
  }
}
`