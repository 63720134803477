<template>
  <v-row justify="center" align="center" no-gutters>
    <v-col v-if="showResult" align="center" cols="10">
      <v-row justify="center" align="center">
        <v-col cols="4">
          <v-card height="700" class="containerBorder scrollClass">
            <v-list>
              <v-list-item v-for="(item, i) in caseDetail" :key="i">
                <v-list-item-content color="primary">
                  <v-card @click="handleMultipleResults(i)" color="primary">
                    <v-card-text class="white--text text-h5">
                      <v-icon
                        v-if="item.crt_casesealed === '1'"
                        left
                        color="white"
                      >
                        mdi-lock
                      </v-icon>
                      {{ item.crt_caseno }}
                    </v-card-text>
                  </v-card>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col>
          <v-card height="700" class="containerBorder scrollClass py-6 px-3">
            <v-card-title class="d-flex justify-center text-h4 font-weight-bold"
              >Hearing Information</v-card-title
            >
            <div v-if="selectedCaseDetails.crt_casesealed === '1'">
              <v-icon size="100" color="primary"> mdi-lock </v-icon>
              <v-card-text
                class="d-flex justify-center text-h4 font-weight-bold"
              >
                Sealed Case
              </v-card-text>
              <v-card-text
                class="d-flex justify-center text-h4 font-weight-bold"
              >
                <v-btn
                  class="text-h4 pa-5 px-5"
                  color="primary"
                  dark
                  @click="$store.dispatch('requestQnA', 'clearks office')"
                  x-large
                >
                  <span>Click Here to Visit Clerk's Office </span>
                </v-btn>
              </v-card-text>
            </div>

            <v-simple-table v-else>
              <template v-slot:default>
                <tbody>
                  <tr class="py-10">
                    <td class="text-h4 py-7">Case #</td>
                    <td class="text-h4">
                      {{ selectedCaseDetails.crt_caseno }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-h4 py-7">Date</td>
                    <td class="text-h4">
                      {{
                        selectedCaseDetails.crt_hearingdatetime
                          | formatStringDate
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-h4 py-7">Time</td>
                    <td class="text-h4">
                      {{
                        selectedCaseDetails.crt_hearingdatetime
                          | formatStringTime
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-h4 py-7">Assigned Judge</td>
                    <td class="text-h4">{{ selectedCaseDetails.crt_judge }}</td>
                  </tr>
                  <tr>
                    <td class="text-h4 py-7">Courtroom Location</td>
                    <td>
                      <v-btn
                        class="text-h4 pa-5 px-5"
                        color="primary"
                        dark
                        @click="handleCourtLocation"
                        x-large
                      >
                        <span
                          v-if="
                            selectedCaseDetails.hasOwnProperty('crt_courtroom')
                          "
                        >
                          {{ selectedCaseDetails.crt_courtroom }}
                        </span>
                        <span v-else> Visit Clerk's Office </span>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <v-col v-else align="center" cols="10">
      <v-card class="containerBorder scrollClass">
        <v-row class="py-10" no-gutters align="center">
          <v-col cols="3" align="end">
            <span class="text-h5 mr-16"> {{$t('searchcaseno')}} </span>
          </v-col>
          <v-col cols="6">
            <v-text-field
              class="text-h5 mx-4"
              v-bind:placeholder="$t('casenoplaceholder')"
              v-bind:label="$t('casenolabel')"
              hide-details
              v-model="caseNoSearch"
              @click="searchMethod = 'number'"
              outlined
            >
            </v-text-field>
          </v-col>
          <v-col align="center" cols="3">
            <v-btn
              width="280"
              x-large
              color="primary"
              @click="searchByNumber()"
              dark
            >
              <v-icon left> mdi-magnify </v-icon>
              {{$t('casenobutton')}}
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="my-5" color="grey" width="90%"></v-divider>
        <v-row class="py-10" no-gutters align="center">
          <v-col cols="3" align="end">
            <span class="text-h5 mr-16"> {{$t('searchcasename')}}: </span>
          </v-col>
          <v-col cols="6">
            <v-row no-gutters>
              <v-col cols="5">
                <v-text-field
                  class="text-h5 mx-4"
                  v-bind:placeholder="$t('firstnamelabel')"
                  v-bind:label="$t('firstnamelabel')"
                  hide-details
                  v-model="nameSearch.firstName"
                  @click="searchMethod = 'firstName'"
                  @focus="searchMethod = 'firstName'"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  class="text-h5 mx-4"
                  v-bind:placeholder="$t('middlenamelabel')"
                  v-bind:label="$t('middlenamelabel')"
                  hide-details
                  v-model="nameSearch.middleName"
                  @click="searchMethod = 'middleName'"
                  @focus="searchMethod = 'middleName'"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  class="text-h5 mx-4"
                  v-bind:placeholder="$t('lastnamelabel')"
                  v-bind:label="$t('lastnamelabel')"
                  hide-details
                  v-model="nameSearch.lastName"
                  @click="searchMethod = 'lastName'"
                  @focus="searchMethod = 'lastName'"
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col align="center" cols="3">
            <v-btn
              width="280"
              x-large
              color="primary"
              @click="searchByName()"
              dark
            >
              <v-icon left> mdi-magnify </v-icon>
              {{$t('searchnamebutton')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-card width="60%">
        <SimpleKeyboard
          class="keyboard"
          @onKeyPress="onKeyPress"
          @onChange="onChange"
        >
        </SimpleKeyboard>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import SimpleKeyboard from "@/components/SimpleKeyboard";

let config = require("./../../config/config");
import moment from "moment";
export default {
  name: "todayshearing",
  data() {
    return {
      caseNoSearch: "",
      nameSearch: {
        firstName: "",
        middleName: "",
        lastName: "",
      },
      searchMethod: "name",
      showResult: false,
      caseDetail: null,
      selectedCaseDetails: null,
    };
  },
  components: {
    SimpleKeyboard,
  },
  methods: {
    onChange(input) {
      // if (this.searchMethod === "name") {
      //   this.nameSearch = input;
      // } else {
      //   this.caseNoSearch = input.toUpperCase();
      //   if (
      //     this.caseNoSearch.length === 1 ||
      //     this.caseNoSearch.length === 5 ||
      //     this.caseNoSearch.length === 8 ||
      //     this.caseNoSearch.length === 13
      //   ) {
      //     this.caseNoSearch = this.caseNoSearch + "-";
      //   }
      //   this.caseNoSearch = input;
      // }
    },
    onKeyPress(button) {
      if (
        button === "{shift}" ||
        button === "{lock}" ||
        button === "{tab}" ||
        button === "{SHIFT}" ||
        button === "{LOCK}" ||
        button === "{TAB}"
      ) {
        return;
      } else if (button === "{bksp}") {
        if (this.searchMethod === "firstName") {
          this.nameSearch.firstName = this.nameSearch.firstName.slice(0, -1);
        } else if (this.searchMethod === "middleName") {
          this.nameSearch.middleName = this.nameSearch.middleName.slice(0, -1);
        } else if (this.searchMethod === "lastName") {
          this.nameSearch.lastName = this.nameSearch.lastName.slice(0, -1);
        } else {
          this.caseNoSearch = this.caseNoSearch.slice(0, -1);
        }
      } else if (button === "{space}") {
        if (this.searchMethod === "firstName") {
          this.nameSearch.firstName = this.nameSearch.firstName + " ";
        } else if (this.searchMethod === "middleName") {
          this.nameSearch.middleName = this.nameSearch.middleName + " ";
        } else if (this.searchMethod === "lastName") {
          this.nameSearch.lastName = this.nameSearch.lastName + " ";
        } else {
          this.caseNoSearch = this.caseNoSearch + " ";
        }
      } else {
        if (this.searchMethod === "firstName") {
          this.nameSearch.firstName = this.nameSearch.firstName + button;
        } else if (this.searchMethod === "middleName") {
          this.nameSearch.middleName = this.nameSearch.middleName + button;
        } else if (this.searchMethod === "lastName") {
          this.nameSearch.lastName = this.nameSearch.lastName + button;
        } else {
          this.caseNoSearch = this.caseNoSearch + button.toUpperCase();
          if (this.caseNoSearch[0] === "D") {
            if (
              this.caseNoSearch.length === 1 ||
              this.caseNoSearch.length === 5 ||
              this.caseNoSearch.length === 8 ||
              this.caseNoSearch.length === 13
            ) {
              this.caseNoSearch = this.caseNoSearch + "-";
            }
          } else if (this.caseNoSearch[0] === "M") {
            if (
              this.caseNoSearch.length === 1 ||
              this.caseNoSearch.length === 4 ||
              this.caseNoSearch.length === 7 ||
              this.caseNoSearch.length === 12
            ) {
              this.caseNoSearch = this.caseNoSearch + "-";
            }
          }
        }
      }
    },
    searchByName() {
      if (this.nameSearch.firstName === "" || this.nameSearch.lastName === "") {
        this.$store.dispatch(
          "avatarSpeak",
          "First or last name cannot be left blank"
        );
      } else {
        this.$store.dispatch("searchByName", this.nameSearch).then(
          (response) => {
            // Touch Usage - Logged in Firebase
            this.$store.commit("setTouchRequest", {
              module: "Todays Hearing",
              action: "Search by Name - " + this.nameSearch.firstName + ' ' + this.nameSearch.middleName + ' ' + this.nameSearch.lastName,
              response: "Case Data Found in Database",
              timeStamp: new Date(),
              requestType: "Touch",
            });
            if (response.length === 0) {
              this.$store.dispatch(
                "avatarSpeak",
                "Sorry! I could not find any results for the entered name. Make sure you entered the name correctly, or try searching with a case number."
              );
            } else if (response.length > 2) {
              this.$store.dispatch(
                "avatarSpeak",
                "I found multiple case numbers for this name. Please select from the list."
              );
              this.caseDetail = response;
              this.showResult = true;
              this.selectedCaseDetails = this.caseDetail[0];
            } else {
              this.$store.dispatch(
                "avatarSpeak",
                "Click on the case number to view the information on the requested name. You can also click on the courtroom location to see the map."
              );
              this.caseDetail = response;
              this.showResult = true;
              this.selectedCaseDetails = this.caseDetail[0];
            }
          },
          (error) => {
            this.$store.dispatch("avatarSpeak", error);

            // Touch Usage - Logged in Firebase
            this.$store.commit("setTouchRequest", {
              module: "Todays Hearing",
              action: "Search by Name - " + this.nameSearch.firstName + ' ' + this.nameSearch.middleName + ' ' + this.nameSearch.lastName,
              response: "Case Data not found in Database",
              timeStamp: new Date(),
              requestType: "Touch",
            });
          }
        );
      }
    },
    searchByNumber() {
      if (this.caseNoSearch === "") {
        this.$store.dispatch("avatarSpeak", "Case Number cannot be left blank");
      } else if (
        this.caseNoSearch.match(/D-\d{3}-[^a-z]{2}-\d{4}-\d{5}/g) === null &&
        this.caseNoSearch.match(/M-\d{2}-[^a-z]{2}-\d{4}-\d{5}/g) === null
      ) {
        this.$store.dispatch(
          "avatarSpeak",
          "Please make sure you have entered the correct case number."
        );
        return;
      } else {
        this.$store.dispatch("searchByCaseNo", this.caseNoSearch).then(
          (response) => {
            if (response.length === 0) {
              //received empty dataset
              this.$store.dispatch(
                "avatarSpeak",
                "Sorry! I did not find any results for this case number."
              );
              this.$store.commit("setTouchRequest", {
                module: "Todays Hearing",
                action: "Search by Case Number - " + this.caseNoSearch,
                response: "Case Number not found",
                timeStamp: new Date(),
                requestType: "Touch",
              });
            } else {
              //received dataset for requested case number
              this.$store.dispatch(
                "avatarSpeak",
                "Here is the information on the requested case number. Click on the courtroom location to see the map."
              );
              this.caseDetail = response;
              this.showResult = true;
              this.selectedCaseDetails = this.caseDetail[0];

              // Touch Usage - Logged in Firebase
              this.$store.commit("setTouchRequest", {
                module: "Todays Hearing",
                action: "Search by Case Number - " + this.caseNoSearch,
                response: "Case Data Found in dataXchange",
                timeStamp: new Date(),
                requestType: "Touch",
              });
            }
          },
          (error) => {
            this.$store.dispatch(
              "avatarSpeak",
              "Sorry! I could not find any results for the entered case number."
            );
            // Touch Usage - Logged in Firebase
            this.$store.commit("setTouchRequest", {
              module: "Todays Hearing",
              action: "Search by Case Number - " + this.caseNoSearch,
              response: "Case Number not found in dataXchange",
              timeStamp: new Date(),
              requestType: "Touch",
            });
          }
        );
      }
    },
    handleMultipleResults(caseIndex) {
      this.selectedCaseDetails = this.caseDetail[caseIndex];
    },
    handleCourtLocation() {
      if (
        Object.prototype.hasOwnProperty.call(
          this.selectedCaseDetails,
          "crt_courtroom"
        )
      ) {
        if (
          this.selectedCaseDetails.crt_court.toLowerCase() ===
          "eighth judicial district taos county"
        ) {
          console.log('Requested courtroom to QnA', 'district ' + this.selectedCaseDetails.crt_courtroom)
          this.$store.dispatch(
            "requestQnA",
            'district ' + this.selectedCaseDetails.crt_courtroom
          );
        }
        else{
          this.$store.dispatch(
            "requestQnA",
            'magistrate ' + this.selectedCaseDetails.crt_courtroom
          );
        }
      } else {
        this.$store.dispatch("requestQnA", "clearks office");
      }
    },
  },
  mounted() {},
  filters: {
    formatStringDate(val) {
      if (!val) {
        return "-";
      }
      return moment(val).format("LL");
    },
    formatStringTime(val) {
      if (!val) {
        return "-";
      }
      return moment(val).format("LT");
    },
  },
};
</script>

<style>
</style>

<i18n>
{
  "en": {
      "searchcaseno": "Search by case number",
      "searchcasename": "Search by name",
      "firstnamelabel": "First Name",
      "middlenamelabel": "Middle Name",
      "lastnamelabel": "Last Name",
      "casenolabel": "Case Number",
      "casenobutton": "Case Number Search",
      "searchnamebutton": "Search by Name",
      "casenoplaceholder": "Tap to enter case number. E.g. D-xx-xxx-xxxx-xxxxx"
    },
  "es":{
      "searchcaseno": "Buscar por número de caso",
      "searchcasename": "Buscar por nombre",
      "firstnamelabel": "Primer nombre",
      "middlenamelabel": "Segundo nombre",
      "lastnamelabel": "Apellido",
      "casenolabel": "Número de caso",
      "casenobutton": "Número de caso",
      "searchnamebutton": "Buscar por nombre",
      "casenoplaceholder": "Toque para ingresar el número de caso. P.ej. D-xx-xxx-xxxx-xxxxx"
    }
}
</i18n>
