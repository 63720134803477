<template>
  <v-dialog
    v-model="getImageViewer.show"
    width="65%"
    @click:outside="$store.commit('closeImageViewer')"
  >
    <v-card>
      <v-card-title class="primary pa-2">
        <v-row no-gutters>
          <v-col cols="10" align-self="center" align="center" class="pl-16">
            <span
              class="
                text-h4
                justify-center
                white--text
                text--darken-3
                font-weight-bold
                pl-16
              "
              >{{ getImageViewer.name }}
            </span></v-col
          >
          <v-col cols="2" align="end">
            <v-progress-circular
              @click="$store.commit('closeImageViewer')"
              :rotate="180"
              :size="65"
              :width="7"
              :value="closePercentage"
              color="white"
            >
              <v-icon large color="white"> mdi-close </v-icon>
            </v-progress-circular>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-img :src="getImageViewer.url"></v-img>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "imageViewer",
  data() {
    return {
      closePercentage: 0,
      timeout: 0,
      intervalObj: 0,
    };
  },
  computed: {
    ...mapGetters(["getImageViewer", "getIsSpeaking"]),
  },
  watch: {
    getIsSpeaking(newVal) {
      if (!newVal) {
        clearTimeout(this.timeout);
        this.intervalObj = setInterval(() => {
          this.closePercentage += 20;
        }, 1000);
        this.timeout = setTimeout(() => {
          clearInterval(this.intervalObj);
          this.closePercentage = 0;
          this.$store.commit("closeImageViewer");
        }, 5000);
      } else {
        this.closePercentage = 0;
        clearInterval(this.intervalObj);
        clearTimeout(this.timeout);
      }
    },
  },
};
</script>

<style scoped>
</style>
