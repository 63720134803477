<template>
  <v-row justify="center" align="center" no-gutters>
    <v-col cols="6">
      <v-card flat>
        <v-card-text class="d-flex justify-center">
          <v-text-field
            placeholder="Enter name/number to search for forms"
            class="text-h5"
            hide-details
            outlined
            height="65px"
            rounded
            v-model="searchKeyword"
            @click="expand = !expand"
            :append-icon="searchBarIcon"
            @click:append="searchKeyword = ''"
          >
          </v-text-field>
        </v-card-text>
        <v-expand-transition>
          <v-card-text class="d-flex justify-center ma-0 pa-0" v-if="expand">
            <SimpleKeyboard
              class="keyboard"
              @onKeyPress="onKeyPress"
              @onChange="onChange"
            >
            </SimpleKeyboard>
          </v-card-text>
        </v-expand-transition>
      </v-card>
    </v-col>
    <v-col cols="12" align="center">
      <v-card
        v-if="expand || searchKeyword !== ''"
        :ripple="false"
        class="mt-2 containerBorder"
        width="95%"
        flat
        :height="cardHeight"
        @click="expand = false"
      >
        <v-card-text v-if="matchingForms.length === 0">
          <span class="text-h4"> No matching forms found. </span>
        </v-card-text>
        <v-row v-else class="pa-5" no-gutters>
          <v-col
            v-for="form in matchingForms"
            :key="form.id"
            @click="viewDoc(form.formPdf, form.formName, 'Form')"
            class="pa-3"
            cols="4"
          >
            <v-card class="PDFborder" height="90" flat>
              <v-row no-gutters class="fill-height" align="center">
                <v-col cols="2">
                  <v-icon size="45" color="primary lighten-4">
                    mdi-file-pdf-box
                  </v-icon>
                </v-col>
                <v-col cols="10" align="start">
                  <span class="headline">
                    {{ form.formName | trimLength }}
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-card class="mt-1" flat width="95%" v-else>
        <v-tabs v-model="tab" color="primary" height="80">
          <v-tab
            v-for="(formType, i) in $t('formType')"
            :key="i"
            active-class="tabClass"
            class="text-h5 pa-5"
            @click="hideAllFolderContent"
          >
            {{ formType.formSubType }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(formType, i) in $t('formType')" :key="i">
            <v-card flat class="containerBorder" height="500">
              <!-- ---------------------------------------------------------------------------------------------------------------------- -->
              <!--  District and Magistrate Court Section  -->
              <v-row
                class="pa-5"
                no-gutters
                v-if="formType.partition && !openFolder && !openInnerFolder"
              >
                <v-col
                  v-for="(form, i) in $t('courtType').keys()"
                  :key="i"
                  class="pa-3"
                  cols="4"
                >
                  <v-card
                    class="formfolder"
                    color="primary"
                    height="90"
                    dark
                    flat
                    @click="courtDepartment(form)"
                  >
                    <v-row no-gutters class="fill-height" align="center">
                      <v-col cols="2">
                        <v-icon size="45" color="primary lighten-4">
                          mdi-folder-open-outline
                        </v-icon>
                      </v-col>
                      <v-col cols="10" align="start">
                        <span class="headline">
                          {{ form | trimLength }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>

              <!-- ------------------------------------------------------------------------------------    -->
              <!--  Court Category Section  -->
              <v-row v-if="openFolder" class="pa-5" no-gutters>
                <v-col cols="12" align="center">
                  <v-icon
                    @click="hideFolderContent"
                    left
                    size="50"
                    color="primary"
                  >
                    mdi-arrow-left
                  </v-icon>
                  <span class="text-h5 font-weight-medium">
                    {{ folderDisplayName }} Forms
                  </span>
                </v-col>
                <v-col
                  v-for="(formCategory, i) in departments"
                  :key="i"
                  class="pa-3"
                  cols="4"
                >
                  <v-card
                    class="formfolder"
                    dark
                    color="primary"
                    height="90"
                    flat
                    @click="
                      displayFolderContent(
                        formCategory.categoryName,
                        formCategory.categoryType
                      )
                    "
                  >
                    <v-row no-gutters class="fill-height" align="center">
                      <v-col cols="2">
                        <v-icon size="45" color="primary lighten-4">
                          mdi-folder-open-outline
                        </v-icon>
                      </v-col>
                      <v-col cols="10" align="start">
                        <span class="headline">
                          {{ formCategory.categoryName | trimLength }} Forms
                        </span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>

              <!--  ---------------------------------------------------------------------------------------            -->

              <v-row v-if="openInnerFolder" class="pa-5" no-gutters>
                <v-col cols="12" align="center">
                  <v-icon
                    @click="hideFolderContent"
                    left
                    size="50"
                    color="primary"
                  >
                    mdi-arrow-left
                  </v-icon>
                  <span class="text-h5 font-weight-medium">
                    {{ folderDisplayName }} Forms
                  </span>
                </v-col>
                <v-col
                  v-for="form in formListByCategory"
                  :key="form.id"
                  @click="viewDoc(form.formPdf, form.formName, 'Form')"
                  class="pa-3"
                  cols="4"
                >
                  <v-card class="PDFborder" height="100" flat>
                    <v-row no-gutters class="fill-height" align="center">
                      <v-col cols="2">
                        <v-icon size="45" color="primary lighten-3">
                          mdi-file-pdf-box
                        </v-icon>
                      </v-col>
                      <v-col cols="10" align="start">
                        <span class="headline">
                          {{ form.formName | trimLength }} Form
                        </span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>

              <!-- ----------------------------------------------------------------------------------------------------------------------------- -->

              <v-row
                v-if="formType.displayType === 'pdf_button'"
                class="pa-5"
                no-gutters
              >
                <v-col
                  v-for="form in $t('forms').values()"
                  :key="form.id"
                  @click="viewDoc(form.formPdf, form.formName, 'Form')"
                  class="pa-3"
                  cols="4"
                >
                  <v-card class="PDFborder" height="100" flat>
                    <v-row no-gutters class="fill-height" align="center">
                      <v-col cols="2">
                        <v-icon size="45" color="primary lighten-4">
                          mdi-file-pdf-box
                        </v-icon>
                      </v-col>
                      <v-col cols="10" align="start">
                        <span class="headline">
                          {{ form.formName | trimLength }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>

              <v-row
                v-if="formType.displayType === 'folder_button'"
                class="pa-5"
                no-gutters
              >
                <v-col
                  v-for="form in $t('formPackets').values()"
                  :key="form.id"
                  @click="
                    viewDoc(
                      form.packetFiles,
                      form.packetName,
                      'Packet',
                      form.packetDesc,
                      form.packetLink
                    )
                  "
                  class="pa-3"
                  cols="4"
                >
                  <v-card
                    class="formfolder"
                    dark
                    color="primary"
                    height="100"
                    flat
                  >
                    <v-row no-gutters class="fill-height" align="center">
                      <v-col cols="2">
                        <v-icon size="45" color="primary lighten-4">
                          mdi-folder-table-outline
                        </v-icon>
                      </v-col>
                      <v-col cols="10" align="start">
                        <span class="headline">
                          {{ form.packetName | trimLength }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import SimpleKeyboard from "@/components/SimpleKeyboard";

export default {
  name: "forms",
  data() {
    return {
      tab: 0,
      searchKeyword: "",
      searchBarIcon: "mdi-magnify",
      expand: false,
      cardHeight: 560,
      folderName: "",
      folderDisplayName: "",
      src: "",
      openFolder: false,
      openInnerFolder: false,
      departments: [],
      category: "",
      currentSelection: "",
    };
  },
  components: {
    SimpleKeyboard,
  },
  computed: {
    ...mapGetters(["getQnaKeyword", "getCourt"]),
    formListByCategory() {
      let temp = [];
      this.$i18n.t("forms").forEach((form) => {
        if (
          form.formCategory.toLowerCase().includes(this.category.toLowerCase())
        ) {
          temp.push(form);
        }
      });
      return temp;
    },
    matchingForms() {
      let temp = [];
      this.$i18n.t("forms").forEach((form) => {
        if (
          form.formName.toLowerCase().includes(this.searchKeyword.toLowerCase())
        ) {
          temp.push(form);
        }
      });
      return temp;
    },
  },
  watch: {
    expand(newVal) {
      if (newVal) {
        this.searchBarIcon = "mdi-close-circle";
        this.cardHeight = 380;
      } else {
        this.searchBarIcon = "mdi-magnify";
        this.cardHeight = 560;
      }
    },
    getQnaKeyword(newVal) {
      this.tab = 0;
      if (this.getCourt === "district court") {
        this.$store.commit("setCourt", "District Court");
      } else {
        this.$store.commit("setCourt", "Magistrate Court");
      }
      let types = this.$i18n.t("courtType").get(this.getCourt);
      types.forEach((type) => {
        if (type.categoryName.toLowerCase() === newVal) {
          this.displayFolderContent(
            type.categoryName,
            type.categoryType.toLowerCase()
          );
        }
      });
    },
  },
  methods: {
    onChange(input) {
      this.searchKeyword = input;
    },
    onKeyPress(button) {
      if (button === "{bksp}")
        this.searchKeyword = this.searchKeyword.slice(0, -1);
    },
    viewDoc(pdfUrl, fileName, fileType, fileDesc, emailLink) {
      this.$store.dispatch("openPdfViewer", {
        url: pdfUrl,
        name: fileName,
        desc: fileDesc,
        emailLink: emailLink,
        type: fileType,
      });
      // Touch Usage
      this.$store.commit("setTouchRequest", {
        module: "Forms",
        action: fileType,
        response: fileName,
        timeStamp: new Date(),
        requestType: "Touch",
      });
    },
    hideFolderContent() {
      if (this.openInnerFolder) {
        this.openInnerFolder = false;
        this.openFolder = true;
        this.folderDisplayName = this.currentSelection;
      } else {
        this.openInnerFolder = false;
        this.openFolder = false;
        this.folderName = "";
        this.folderDisplayName = "";
        this.expand = false;
      }
    },
    hideAllFolderContent() {
      this.openInnerFolder = false;
      this.openFolder = false;
    },
    displayFolderContent(formCategory, categoryType) {
      this.openFolder = false;
      this.openInnerFolder = true;
      this.folderName = formCategory;
      this.folderDisplayName = formCategory;
      this.category = categoryType;
    },
    courtDepartment(courtType) {
      this.openFolder = true;
      this.folderDisplayName = courtType;
      this.currentSelection = courtType;
      this.departments = this.$i18n.t("courtType").get(courtType);
    },
  },
  filters: {
    trimLength(val) {
      if (val.length < 90) {
        return val;
      }
      return `${val.substring(0, 60)}...`;
    },
  },
  mounted() {
    if (this.$i18n.locale === "en") {
      this.tab = 0;
      if (this.getCourt === "district court") {
        this.$store.commit("setCourt", "District Court");
      } else {
        this.$store.commit("setCourt", "Magistrate Court");
      }
      let types = this.$i18n.t("courtType").get(this.getCourt);
      console.log(types);
      types.forEach((type) => {
        if (type.categoryName.toLowerCase() === this.getQnaKeyword) {
          this.displayFolderContent(
            type.categoryName,
            type.categoryType.toLowerCase()
          );
        }
      });
    }
  },
};
</script>

<style>
.formfolder {
  border-radius: 15px !important;
}
</style>
