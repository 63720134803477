<template>
  <v-row no-gutters align="start" class="mb-n15">
    <v-col cols="2">
      <v-card height="70" flat width="100%">
        <v-card-title class="text-h4 justify-center font-weight-bold">
          Map Directory
        </v-card-title>
      </v-card>
      <v-card
        height="730"
        flat
        color="primary"
        align="center"
        class="overflow-y-auto py-4"
      >
        <v-btn
          v-for="(room, i) in maps"
          :key="i"
          width="95%"
          height="50"
          class="my-1 white--text"
          @click="
            selectImage(room.mapImage, room.mapFloor, room.speech, room.mapName)
          "
          outlined
        >
          <v-row>
            <v-col cols="1">
              <v-icon> mdi-map-marker </v-icon>
            </v-col>
            <v-col cols="10" align="left" class="pt-4 pl-5">
              {{ room.mapName | trimLength }}
            </v-col>
          </v-row>
        </v-btn>
      </v-card>
    </v-col>
    <v-col cols="10">
      <v-tabs
        active-class="tabClass"
        background-color="primary"
        centered
        height="70"
        dark
        v-model="active_tab"
      >
        <v-tab
          v-for="(floor, index) in $t('floors')"
          :key="index"
          @click="
            selectImage(
              floor.floorImage.url,
              floor.floorNum,
              floor.speech,
              floor.floorName
            );
            selectedFloor = floor.floorNumber;
          "
          class="text-h5 pa-5"
        >
          {{ floor.floorName }}
        </v-tab>
      </v-tabs>
      <v-row>
        <v-col align="center">
          <v-img :src="url" width="79%"> </v-img>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "maps",
  data() {
    return {
      active_tab: 0,
      url: "",
      floorNum: null,
      showList: false,
      count: 0,
    };
  },
  computed: {
    ...mapGetters(["getMaps", "getCMSlink"]),
    maps() {
      let temp = [];
      this.$i18n.t("maps").forEach((map) => {
        temp.push(map);
      });

      return temp;
    },
    listHeight() {
      console.log(this.maps.length);
      if (this.maps.length === 2) {
        return 125;
      } else if (this.maps.length > 2 && this.maps.length < 6) {
        return 160;
      } else if (this.maps.length === 6) {
        return 250;
      } else {
        return 300;
      }
    },
  },
  methods: {
    selectImage(mapPath, mapFloor, speech, mapName) {
      this.active_tab = mapFloor - 1;
      this.url = mapPath;

      // Condition added to avoid redundant call from Mounted
      if (speech !== undefined) {
        this.$store.dispatch("avatarSpeak", speech);
        // Touch Usage
        this.$store.commit("setTouchRequest", {
          module: "Maps and Directory",
          action: "View Map",
          response: mapName,
          timeStamp: new Date(),
          requestType: "Touch",
        });
      }
    },
    showMaps(num) {
      if (this.floorNum === num) {
        this.showList = false;
        this.floorNum = 0;
      } else {
        this.floorNum = num;
        this.showList = true;
      }
    },
  },
  filters: {
    trimLength(val) {
      if (val.length < 20) {
        return val;
      }
      return `${val.substring(0, 20)}...`;
    },
  },
  mounted() {
    this.selectImage(
      this.$i18n.t("floors")[0].floorImage.url,
      this.$i18n.t("floors").floorNum
    );
  },
};
</script>
