import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from './store'
Vue.use(VueI18n)

// The getters are to be called in the way below only when Map Datastructure is used.

// Getters for Maps -> English Language
let getMapsEnglish = () => {
  return store.getters.getMapsEnglish
}

// Getters for Maps -> Spanish Language
let getMapsSpanish = () => {
  return store.getters.getMapsSpanish
}

// Getters for Faq Category -> English Language
let getFaqCategoriesEnglish = () => {
  return store.getters.getFaqCategoriesEnglish
}

// Getters for Faq Category -> Spanish Language
let getFaqCategoriesSpanish = () => {
  return store.getters.getFaqCategoriesSpanish
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: {
    en: {
      // Homepage
      homepages: store.getters.getHomepageEnglish,
      // Faqs
      faqs: store.getters.getFaqsEnglish,
      faqCategory: getFaqCategoriesEnglish,     
      // Maps
      maps: getMapsEnglish,
      floors: store.getters.getFloorsEnglish,
    },
    es: {
      // Homepage
      homepages: store.getters.getHomepageSpanish,
      // Faqs
      faqs: store.getters.getFaqsSpanish,
      faqCategory: getFaqCategoriesSpanish,
      // Maps
      maps: getMapsSpanish,
      floors: store.getters.getFloorsSpanish,
    }
  }
})
